.dashboard {
	/* padding-top: 20vh; */
}

input[type='text'] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	border: 3px solid rgb(0, 0, 0);
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
}

input[type='text']:focus {
	border: 3px solid rgb(120, 4, 4);
}

.withdraw {
	min-height: 38.7vh;
	height: auto;
	overflow: hidden !important;
}

.transfer {
	min-height: 38.7vh;
	height: auto;
	overflow: hidden !important;
}

.login {
	width: 45% !important;
}

.loginimg {
	margin: 3vh 20%;
}

.loginbtn {
	margin: 1rem 0;
}

.wallet_id {
	font-weight: bold;
}

.balanceText {
	display: block;
	margin-top: 5px;
}

h3 {
	font-weight: lighter;
}

.loginform {
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: #061343;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.sidetext {
	background-color: #061343;
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: wheat;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.nav {
	text-decoration: none;
	color: #061343;
}

.link,
a {
	text-decoration: none !important;
}

.contributebtn,
.withdrawbtn,
.transferbtn {
	margin: 30px 0;
}

.contributebtn a,
.withdrawbtn a,
.transferbtn a {
	box-shadow: 1px 1px #ccc;
}

a:disabled,
a[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	cursor: text;
}

.contributebtn a {
	display: flex;
	justify-content: center;
	align-self: center;
	background: #061343;
	width: 100%;
	height: 12vh;
	line-height: 12vh;
	border-radius: 5px;
	font-size: 1.2rem;
}

.withdrawbtn a {
	display: flex;
	justify-content: center;
	align-self: center;
	background: #f8e404;
	width: 100%;
	height: 12vh;
	line-height: 12vh;
	border-radius: 5px;
	font-size: 1.2rem;
}

.transferbtn a {
	display: flex;
	justify-content: center;
	align-self: center;
	background: #04f88a;
	width: 100%;
	height: 12vh;
	line-height: 12vh;
	border-radius: 5px;
	font-size: 1.2rem;
}

.form {
	margin: 10px 0;
}

.wallet {
	padding-bottom: 35px;
}

.targets {
	padding-bottom: 5px;
}

.loading {
	margin: 15% auto;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.loadingText {
	width: 125px;
	height: 30px;
	margin: 0 auto;
	display: flex;
}

.table > thead > tr > th {
	border-bottom-width: 1px;
	font-size: 1.0625rem;
	font-weight: 300;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 12px 8px;
	vertical-align: middle;
	border-color: #ddd;
}

.table thead tr th {
	font-size: 1.063rem;
}

.table > tbody > tr {
	position: relative;
}

.table-inverse {
	color: rgba(255, 255, 255, 0.84);
}

.table thead th {
	font-size: 0.95rem;
	font-weight: 500;
	border-top-width: 0;
	border-bottom-width: 1px;
}

thead.thead-inverse th,
.table-inverse thead th {
	color: rgba(255, 255, 255, 0.54);
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
	border-color: rgba(255, 255, 255, 0.06);
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.table.table-hover tbody tr:hover {
	background-color: #f5f5f5;
}

.dataTable > thead > tr > th,
.dataTable > tbody > tr > th,
.dataTable > tfoot > tr > th,
.dataTable > thead > tr > td,
.dataTable > tbody > tr > td,
.dataTable > tfoot > tr > td {
	padding: 5px !important;
}

table.dataTable {
	clear: both;
	margin-top: 6px !important;
	margin-bottom: 6px !important;
	max-width: none !important;
	border-collapse: separate !important;
	border-spacing: 0;
}

table.dataTable td,
table.dataTable th {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
	text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
	width: auto;
	display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
	text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
	font-weight: normal;
	white-space: nowrap;
	text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0.5em;
	display: inline-block;
	width: auto;
}

div.dataTables_wrapper div.dataTables_info {
	padding-top: 0.85em;
	white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
	margin: 0;
	white-space: nowrap;
	text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	margin: 2px 0;
	white-space: nowrap;
	justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	margin-left: -100px;
	margin-top: -26px;
	text-align: center;
	padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
	padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
	outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
	position: absolute;
	bottom: 0.9em;
	display: block;
	opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
	top: 2px;
	right: 1em;
	content: '\2191';
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
	top: 2px;
	right: 0.5em;
	content: '\2193';
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
	opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
	opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
	border-top: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
	display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
	border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
	box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
	margin-top: 0 !important;
	border-top: none;
}

table.dataTable.table-sm > thead > tr > th {
	padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
	top: 5px;
	right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
	top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
	border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
	border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
	border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
	border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
	margin: 0;
}

div.table-responsive
	> div.dataTables_wrapper
	> div.row
	> div[class^='col-']:first-child {
	padding-left: 0;
}

div.table-responsive
	> div.dataTables_wrapper
	> div.row
	> div[class^='col-']:last-child {
	padding-right: 0;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 801px) {
}
