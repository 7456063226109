.signup {
	width: 60% !important;
	/* padding-top: 15vh; */
}

.form {
	margin: 10vh 0;
}

.signupform {
	margin: 5vh auto;
	padding: 25px;
	border: 1px solid #eee;
	background-color: #061343;
	color: wheat;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

h3 {
	font-weight: lighter;
}

.signinimg {
	width: 90%;
	margin: 6vh 0;
}

.sidetext {
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: #061343;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.signupbtn {
	margin: 2rem 0;
	width: 100%;
}

.nav {
	text-decoration: none;
	color: #ccc;
}

.loading {
	margin: 15% auto;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.loadingText {
	width: 125px;
	height: 30px;
	margin: 0 auto;
	display: flex;
}

@media only screen and (max-width: 801px) {
	.signup {
		width: 90% !important;
	}
	.form {
		margin: 0;
	}
	.signinimg {
		display: none;
	}
	.signupform {
		border-radius: 1rem;
		margin: 2vh auto;
		width: 100%;
	}
	.sidetext {
		display: none;
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
