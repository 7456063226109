.login {
	width: 45% !important;
	/* padding-top: 20vh; */
}

.upper_text {
	color: #303030 !important;
}

.loginimg {
	margin: 3vh 20%;
}

.loginbtn {
	margin: 1rem 0;
}

h3 {
	font-weight: lighter;
}

.loginform {
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: #061343;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.sidetext {
	background-color: #061343;
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: wheat;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.nav {
	text-decoration: none;
	color: #061343;
}

.loading {
	margin: 15% auto;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.loadingText {
	width: 125px;
	height: 30px;
	margin: 0 auto;
	display: flex;
}

@media only screen and (max-width: 801px) {
	.login {
		width: 90% !important;
	}
	.form {
		margin: 0;
	}
	.logininimg {
		display: none;
	}
	.loginform {
		border-radius: 1rem;
		margin: 2vh auto;
		width: 100%;
	}
	.input {
		border: 1px solid #ccc !important;
	}
	.sidetext {
		display: none;
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
