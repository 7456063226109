.header {
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff;
	padding: 0 10%;
}

.logo {
	/* padding: 20px 0; */
	width: 70px !important;
}

form input {
	border: none !important;
}

.header ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: baseline;
}

.header li {
	margin-left: 3rem;
}

.nav,
.header a {
	text-decoration: none;
	color: #707070;
	padding: 1rem;
}

.btnheader {
	background-color: #cf173c !important;
}

.btn-flat {
	background-color: purple;
	color: white;
}

.btn-xxl {
	padding: 1rem 1.5rem;
	font-size: 1.5rem;
}

.header a:hover,
.header a:active,
.header a.active {
	color: #71962d;
}

.badge {
	background-color: #cc2062;
	color: white;
	border-radius: 12px;
	padding: 0 1rem;
	margin-left: 0.5rem;
}

.loading {
	margin: 15% auto;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.loadingText {
	width: 125px;
	height: 30px;
	margin: 0 auto;
	display: flex;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
