.contribute {
	/* padding-top: 20vh; */
}

.input {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	border: 3px solid rgb(0, 0, 0);
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
}

.withdraw {
	min-height: 38.7vh;
	height: auto;
	overflow: hidden !important;
	padding-top: 20vh;
}

.transfer {
	min-height: 38.7vh;
	height: auto;
	overflow: hidden !important;
	padding-top: 20vh;
}

.login {
	width: 45% !important;
}

.loginimg {
	margin: 3vh 20%;
}

.loginbtn {
	margin: 1rem 0;
}

h3 {
	font-weight: lighter;
}

.loginform {
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: #061343;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.sidetext {
	background-color: #061343;
	margin: 5vh auto;
	padding: 40px;
	border: 1px solid #eee;
	color: wheat;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.nav {
	text-decoration: none;
	color: #061343;
}

.link,
a {
	text-decoration: none !important;
}

.beneficiary {
	color: #061343;
	padding: 1rem;
}

.contributebtn {
	background: #061343;
	border-radius: 5px;
	color: #eee;
	padding: 0.75rem;
	border: none;
}

.withdrawbtn {
	background: #f8e404;
	border-radius: 5px;
	padding: 0.75rem;
	border: none;
}

.transferbtn {
	background: #04f88a;
	border-radius: 5px;
	padding: 0.75rem;
	border: none;
}

.transferbtn a {
	display: flex;
	justify-content: center;
	align-self: center;
	background: #04f88a;
	width: 100%;
	height: 12vh;
	line-height: 12vh;
	border-radius: 5px;
	font-size: 1.2rem;
}

.form {
	margin: 10px 0;
}

.wallet {
	padding-bottom: 30px;
}
