.headerText {
	padding: 00.8rem;
	font-size: 55px;
	font-weight: lighter;
	margin-top: 5%;
}

.banner {
	margin-left: 70%;
	height: 50vh;
	z-index: 1;
	/* position: absolute; */
	margin-top: -10%;
}

.bannerText {
	font-size: 24px;
	/*z-index: 9999;*/
	margin: -15% 0 7% 0;
	margin-left: 1rem;
}

.btnStart {
	padding: 15px 35px !important;
	margin-left: 1rem;
	background-color: #161616 !important;
	border-radius: 5px;
	margin-top: 45px !important;
}
.bodyContainer {
	margin-top: 25% !important;
	/* margin-left: 1rem; */
}
.subscribeContainer {
	min-height: 300px;
	padding: 20px;
	margin-top: 20px !important;
}
.savingsCard {
	border-radius: 0.7rem;
	background-color: #062231;
	margin: 20px;
	min-height: 300px;
}

.savingsCard h2 {
	color: white;
	font-weight: lighter;
}

.savingsCard p {
	color: white;
	margin: 1.8rem;
}

.cardHeader {
	position: relative;
	margin: 6.5% 25%;
}

.cardImg {
	position: absolute;
	margin: 2% 5%;
	height: 60px;
}

.subscribe {
	border-radius: 0.7rem;
	background-color: #0486cb;
	bottom: 12px;
}
.subscribe h3 {
	color: white;
	margin: 0 auto;
	font-weight: lighter;
	width: 30%;
	margin-top: 3% !important;
}

.subscriber {
	top: 10px !important;
	width: 80% !important;
	margin: 0 auto;
}

.btnsubscribe {
	background-color: #161616 !important;
	color: #f1f1f2 !important;
	font-weight: bold !important;
	border: none !important;
	box-shadow: 1px 1px 2px #062231;
}

@media only screen and (max-width: 801px) {
	.banner {
		display: none;
	}
	.bodyContainer {
		margin-top: 20px !important;
	}

	.bannerText {
		margin-top: 0;
		margin-left: 1rem;
	}
	.cardImg {
		margin-top: 2%;
		height: 50px;
		margin-right: 10%;
	}
	.cardHeader {
		padding-left: 20px;
		font-size: 20px;
		/* font-weight: bold !important; */
	}

	/* .start {
		display: block;
		font-size: 1rem;
	} */

	.savingsCard {
		min-height: 150px;
	}
	.subscribe h3 {
		text-align: center;
		font-size: 20px !important;
	}

	.subscriber {
		top: 10px !important;
		margin: 0 auto;
	}

	.subscribeContainer {
		min-height: 250px;
	}
}
